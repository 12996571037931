import { baseApi } from "store/baseStoreAPI";
import { BackendDataShape } from "types";
import {
  ReportDoctorList,
  ReportPatientList,
  ReportPharmacyTransactionList,
  ReportPolyclinicList,
  ReportProcedureList,
  ReportTransactionByDokterList,
  ReportTransactionByPoliList,
  ResponseReportDrugs,
  ResponseReportKunjungan,
  ResponseReportTransaksi,
} from "types/api/report";

const subEndpoint = "/report";

export const reportApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getPatientKunjunganReport: builder.query<
      ResponseReportKunjungan,
      {
        isBpjs?: boolean;
        query?: string;
        page?: number;
        take?: number;
        startDate: string;
        endDate: string;
      }
    >({
      query: (args) => {
        return {
          url: `${subEndpoint}/pasien/kunjungan`,
          method: "GET",
          params: {
            ...args,
          },
        };
      },
      transformResponse: (res: BackendDataShape<any>) => {
        const { dataJumlah, dataKunjungan } = res.data;
        const items = res.data.dataKunjungan.entities;

        return {
          dataJumlah,
          dataKunjungan: {
            entities: items.map((item: any) => ({
              id: item?.id,
              full_name: item?.patient?.full_name,
              encounter: item?.patient?.encounter,
              nik: item?.patient?.nik,
              address: item?.patient?.address,
              is_bpjs: item?.is_bpjs,
              bpjs_no: item?.patient?.bpjs_no,
              created_date: item?.created_date,
            })),
            meta: dataKunjungan?.meta,
          },
        };
      },
    }),

    getPatientKunjunganExport: builder.query<
      string,
      {
        isBpjs?: boolean;
        query?: string;
        page?: number;
        take?: number;
        startDate: string;
        endDate: string;
      }
    >({
      query: (args) => {
        return {
          url: `${subEndpoint}/pasien/kunjungan/export`,
          method: "GET",
          params: {
            ...args,
          },
          responseHandler: async (response) => {
            if (response.ok) {
              const blob = await response.blob();
              const objectUrl = URL.createObjectURL(blob);
              return objectUrl;
            } else {
              throw new Error("Network response was not ok");
            }
          },
        };
      },
    }),

    getPatientTransaksiReport: builder.query<
      ResponseReportTransaksi,
      {
        isBpjs?: boolean;
        query?: string;
        page?: number;
        take?: number;
        startDate: string;
        endDate: string;
      }
    >({
      query: (args) => {
        return {
          url: `${subEndpoint}/pasien/transaksi`,
          method: "GET",
          params: {
            ...args,
          },
        };
      },
      transformResponse: (res: BackendDataShape<any>) => {
        const { dataJumlah, dataTransaksi } = res.data;
        const items = res.data.dataTransaksi.entities;

        return {
          dataJumlah,
          dataTransaksi: {
            entities: items.map((item: any) => ({
              id: item?.id,
              full_name: item?.medicalRecord?.queue?.patient?.full_name,
              encounter: item?.medicalRecord?.queue?.patient?.encounter,
              nik: item?.medicalRecord?.queue?.patient.nik,
              address: item?.medicalRecord?.queue?.patient?.address,
              is_bpjs: item?.medicalRecord?.queue?.is_bpjs,
              bpjs_no: item?.medicalRecord?.queue?.patient?.bpjs_no,
              created_date: item?.created_date,
              jumlah_transaksi: item?.jumlah_transaksi,
            })),
            meta: dataTransaksi?.meta,
          },
        };
      },
    }),

    getPatientTransaksiExport: builder.query<
      string,
      {
        isBpjs?: boolean;
        query?: string;
        page?: number;
        take?: number;
        startDate: string;
        endDate: string;
      }
    >({
      query: (args) => {
        return {
          url: `${subEndpoint}/pasien/transaksi/export`,
          method: "GET",
          params: {
            ...args,
          },
          responseHandler: async (response) => {
            if (response.ok) {
              const blob = await response.blob();
              const objectUrl = URL.createObjectURL(blob);
              return objectUrl;
            } else {
              throw new Error("Network response was not ok");
            }
          },
        };
      },
    }),

    getDrugsReport: builder.query<
      ResponseReportDrugs,
      {
        page?: number;
        take?: number;
        merk?: string;
      }
    >({
      query: (args) => {
        return {
          url: `/drugs`,
          method: "GET",
          params: {
            ...args,
          },
        };
      },
      transformResponse: (res: BackendDataShape<any>) => {
        const { entities, meta } = res.data;
        const items = entities;

        return {
          dataObat: {
            entities: items.map((item: any) => ({
              id: item?.id,
              merk: item?.merk,
              generic_name: item?.generic_name,
              manufacturer: item?.manufacturer,
              unit: item?.primary_uom,
              purchase_price: item?.purchase_price,
              retail_price: item?.retail_price,
              stock: item?.stock,
            })),
            meta: meta,
          },
        };
      },
    }),

    getDrugsExport: builder.query<string, void>({
      query: () => {
        return {
          url: `${subEndpoint}/obat/export`,
          method: "GET",
          responseHandler: async (response) => {
            if (response.ok) {
              const blob = await response.blob();
              const objectUrl = URL.createObjectURL(blob);
              return objectUrl;
            } else {
              throw new Error("Network response was not ok");
            }
          },
        };
      },
    }),

    getPatientReport: builder.query<
      ReportPatientList,
      {
        startDate?: string;
        endDate?: string;
        isBpjs?: boolean;
        page?: number;
        take?: number;
        query?: string;
        sex?: string;
        polyclinicId?: string;
        practitionerId?: string;
        patientId?: string;
      }
    >({
      query: (args) => {
        return {
          url: `${subEndpoint}/pasien`,
          method: "GET",
          params: {
            ...args,
          },
        };
      },
      transformResponse: (res: BackendDataShape<ReportPatientList>) => {
        return res?.data;
      },
    }),

    getPatientExport: builder.query<
      string,
      {
        sex?: string;
      }
    >({
      query: (args) => {
        return {
          url: `${subEndpoint}/pasien/export`,
          method: "GET",
          params: {
            ...args,
          },
          responseHandler: async (response) => {
            if (response.ok) {
              const blob = await response.blob();
              const objectUrl = URL.createObjectURL(blob);
              return objectUrl;
            } else {
              throw new Error("Network response was not ok");
            }
          },
        };
      },
    }),

    getDoctorReport: builder.query<
      ReportDoctorList,
      {
        page?: number;
        take?: number;
        query?: string;
        polyclinicId?: string;
      }
    >({
      query: (args) => {
        return {
          url: `${subEndpoint}/practitioner`,
          method: "GET",
          params: {
            ...args,
          },
        };
      },
      transformResponse: (res: BackendDataShape<any>) => {
        const { entities, meta } = res.data;
        const items = entities;

        return {
          entities: items.map((item: any) => ({
            id: item?.id,
            username: item?.username,
            nik: item?.userProfile?.nik,
            str_no: item?.userProfile?.str_no,
            first_name: item?.userProfile?.first_name,
            polyclinic_name: item?.polyclinic?.polyclinic_name,
            kd_dokter_bpjs: item?.userProfile?.kd_dokter_bpjs,
            phone_number: item?.phone_number,
            address: item?.userProfile?.address,
          })),
          meta: meta,
        };
      },
    }),

    getDoctorExport: builder.query<
      string,
      {
        polyclinicId?: string;
      }
    >({
      query: (args) => {
        return {
          url: `${subEndpoint}/practitioner/export`,
          method: "GET",
          params: {
            ...args,
          },
          responseHandler: async (response) => {
            if (response.ok) {
              const blob = await response.blob();
              const objectUrl = URL.createObjectURL(blob);
              return objectUrl;
            } else {
              throw new Error("Network response was not ok");
            }
          },
        };
      },
    }),

    getPolyclinicReport: builder.query<
      ReportPolyclinicList,
      {
        page?: number;
        take?: number;
        query?: string;
      }
    >({
      query: (args) => {
        return {
          url: `${subEndpoint}/poli`,
          method: "GET",
          params: {
            ...args,
          },
        };
      },
      transformResponse: (res: BackendDataShape<any>) => {
        const { entities, meta } = res.data;
        const items = entities;

        return {
          entities: items.map((item: any) => ({
            id: item?.id,
            code: item?.code,
            polyclinic_name: item?.polyclinic_name,
            docter_count: item?.userAccounts?.length,
            poli_sakit: item?.poli_sakit,
          })),
          meta: meta,
        };
      },
    }),

    getPolyclinicExport: builder.query<string, void>({
      query: () => {
        return {
          url: `${subEndpoint}/poli/export`,
          method: "GET",
          responseHandler: async (response) => {
            if (response.ok) {
              const blob = await response.blob();
              const objectUrl = URL.createObjectURL(blob);
              return objectUrl;
            } else {
              throw new Error("Network response was not ok");
            }
          },
        };
      },
    }),

    getProcedureReport: builder.query<
      ReportProcedureList,
      {
        page?: number;
        take?: number;
        query?: string;
      }
    >({
      query: (args) => {
        return {
          url: `${subEndpoint}/procedure`,
          method: "GET",
          params: {
            ...args,
          },
        };
      },
      transformResponse: (res: BackendDataShape<any>) => {
        const { entities, meta } = res.data;
        const items = entities;

        return {
          entities: items.map((item: any) => ({
            id: item?.id,
            namespace: item?.namespace,
            procedure_name: item?.procedure_name,
            rate: item?.rate,
          })),
          meta: meta,
        };
      },
    }),

    getProcedureExport: builder.query<string, void>({
      query: () => {
        return {
          url: `${subEndpoint}/procedure/export`,
          method: "GET",
          responseHandler: async (response) => {
            if (response.ok) {
              const blob = await response.blob();
              const objectUrl = URL.createObjectURL(blob);
              return objectUrl;
            } else {
              throw new Error("Network response was not ok");
            }
          },
        };
      },
    }),

    getPharmacyTransactionReport: builder.query<
      ReportPharmacyTransactionList,
      {
        page?: number;
        take?: number;
        query?: string;
        startDate?: string;
        endDate?: string;
      }
    >({
      query: (args) => {
        return {
          url: `${subEndpoint}/pharmacy/transaction`,
          method: "GET",
          params: {
            ...args,
          },
        };
      },
      transformResponse: (res: BackendDataShape<any>) => {
        const { dataJumlah } = res.data;
        const { entities, meta } = res.data?.data;
        const items = entities;

        return {
          total_earning: dataJumlah?.pendapatan_total ?? 0,
          entities: items.map((item: any) => ({
            id: item?.id,
            time: item?.medrecTrx?.modified_date,
            patient_name:
              item?.medrecTrx?.medicalRecord?.queue?.patient?.full_name,
            encounter:
              item?.medrecTrx?.medicalRecord?.queue?.patient?.encounter,
            merk: item?.drug?.merk,
            quantity: item?.quantity,
            price: item?.price,
            total_price: item?.total_harga,
          })),
          meta: meta,
        };
      },
    }),

    getPharmacyTransactionExport: builder.query<
      string,
      {
        startDate: string;
        endDate: string;
      }
    >({
      query: (args) => {
        return {
          url: `${subEndpoint}/pharmacy/transaction/export`,
          method: "GET",
          params: {
            ...args,
          },
          responseHandler: async (response) => {
            if (response.ok) {
              const blob = await response.blob();
              const objectUrl = URL.createObjectURL(blob);
              return objectUrl;
            } else {
              throw new Error("Network response was not ok");
            }
          },
        };
      },
    }),

    getTransactionByPolyReport: builder.query<
      ReportTransactionByPoliList,
      {
        page?: number;
        take?: number;
        query?: string;
        startDate: string;
        endDate: string;
        polyclinicId: string;
      }
    >({
      query: (args) => {
        return {
          url: `${subEndpoint}/transaksi/tindakan/poli`,
          method: "GET",
          params: {
            ...args,
          },
        };
      },
      transformResponse: (res: BackendDataShape<any>) => {
        const { dataJumlah, dataTransaksi } = res?.data;
        const { entities, meta } = dataTransaksi;
        const items = entities;

        return {
          total_earning: dataJumlah?.pendapatan_total ?? 0,
          entities: items.map((item: any) => ({
            id: item?.id,
            time: item?.modified_date,
            patient_name:
              item?.medrecTrx?.medicalRecord?.queue?.patient?.full_name,
            encounter:
              item?.medrecTrx?.medicalRecord?.queue?.patient?.encounter,
            polyclinic_name:
              item?.medrecTrx?.medicalRecord?.queue?.polyclinic
                ?.polyclinic_name,
            procedure_name: item?.procedure?.procedure_name,
            rate: item?.procedure?.rate,
          })),
          meta: meta,
        };
      },
    }),

    getTransactionByPolyExport: builder.query<
      string,
      {
        startDate: string;
        endDate: string;
        polyclinicId: string;
      }
    >({
      query: (args) => {
        return {
          url: `${subEndpoint}/transaksi/tindakan/poli/export`,
          method: "GET",
          params: {
            ...args,
          },
          responseHandler: async (response) => {
            if (response.ok) {
              const blob = await response.blob();
              const objectUrl = URL.createObjectURL(blob);
              return objectUrl;
            } else {
              throw new Error("Network response was not ok");
            }
          },
        };
      },
    }),

    getTransactionByDoctorReport: builder.query<
      ReportTransactionByDokterList,
      {
        page?: number;
        take?: number;
        query?: string;
        startDate: string;
        endDate: string;
        practitionerId: string;
      }
    >({
      query: (args) => {
        return {
          url: `${subEndpoint}/transaksi/tindakan/dokter`,
          method: "GET",
          params: {
            ...args,
          },
        };
      },
      transformResponse: (res: BackendDataShape<any>) => {
        const { dataJumlah, dataTransaksi } = res?.data;
        const { entities, meta } = dataTransaksi;
        const items = entities;

        return {
          total_earning: dataJumlah?.pendapatan_total ?? 0,
          entities: items.map((item: any) => ({
            id: item?.id,
            time: item?.modified_date,
            patient_name:
              item?.medrecTrx?.medicalRecord?.queue?.patient?.full_name,
            encounter:
              item?.medrecTrx?.medicalRecord?.queue?.patient?.encounter,
            doctor_name:
              item?.medrecTrx?.medicalRecord?.queue?.practitioner?.userProfile
                ?.first_name,
            procedure_name: item?.procedure?.procedure_name,
            rate: item?.price,
          })),
          meta: meta,
        };
      },
    }),

    getTransactionByDoctorExport: builder.query<
      string,
      {
        startDate: string;
        endDate: string;
        practitionerId: string;
      }
    >({
      query: (args) => {
        return {
          url: `${subEndpoint}/transaksi/tindakan/dokter/export`,
          method: "GET",
          params: {
            ...args,
          },
          responseHandler: async (response) => {
            if (response.ok) {
              const blob = await response.blob();
              const objectUrl = URL.createObjectURL(blob);
              return objectUrl;
            } else {
              throw new Error("Network response was not ok");
            }
          },
        };
      },
    }),

    getProcedureTransactionByPatientReport: builder.query<
      ReportTransactionByDokterList,
      {
        page?: number;
        take?: number;
        query?: string;
        startDate: string;
        endDate: string;
        patientId: string;
        practitionerId?: string;
      }
    >({
      query: (args) => {
        return {
          url: `${subEndpoint}/transaksi/tindakan/pasien`,
          method: "GET",
          params: {
            ...args,
          },
        };
      },
      transformResponse: (res: BackendDataShape<any>) => {
        const { dataJumlah, dataTransaksi } = res?.data;
        const { entities, meta } = dataTransaksi;
        const items = entities;

        return {
          total_earning: dataJumlah?.pendapatan_total ?? 0,
          entities: items.map((item: any) => ({
            id: item?.id,
            time: item?.modified_date,
            patient_name:
              item?.medrecTrx?.medicalRecord?.queue?.patient?.full_name,
            encounter:
              item?.medrecTrx?.medicalRecord?.queue?.patient?.encounter,
            doctor_name:
              item?.medrecTrx?.medicalRecord?.queue?.practitioner?.userProfile
                ?.first_name,
            procedure_name: item?.procedure?.procedure_name,
            rate: item?.price,
          })),
          meta: meta,
        };
      },
    }),

    getProcedureTransactionByPatientExport: builder.query<
      string,
      {
        startDate: string;
        endDate: string;
        patientId: string;
        practitionerId?: string;
      }
    >({
      query: (args) => {
        return {
          url: `${subEndpoint}/transaksi/tindakan/pasien/export`,
          method: "GET",
          params: {
            ...args,
          },
          responseHandler: async (response) => {
            if (response.ok) {
              const blob = await response.blob();
              const objectUrl = URL.createObjectURL(blob);
              return objectUrl;
            } else {
              throw new Error("Network response was not ok");
            }
          },
        };
      },
    }),

    getPharmacyTransactionByPatientReport: builder.query<
      ReportPharmacyTransactionList,
      {
        page?: number;
        take?: number;
        query?: string;
        startDate: string;
        endDate: string;
        patientId: string;
      }
    >({
      query: (args) => {
        return {
          url: `${subEndpoint}/pharmacy/transaction/pasien`,
          method: "GET",
          params: {
            ...args,
          },
        };
      },
      transformResponse: (res: BackendDataShape<any>) => {
        const { dataJumlah } = res.data;
        const { entities, meta } = res.data?.data;
        const items = entities;

        return {
          total_earning: dataJumlah?.pendapatan_total ?? 0,
          entities: items.map((item: any) => ({
            id: item?.id,
            time: item?.medrecTrx?.modified_date,
            patient_name:
              item?.medrecTrx?.medicalRecord?.queue?.patient?.full_name,
            encounter:
              item?.medrecTrx?.medicalRecord?.queue?.patient?.encounter,
            merk: item?.drug?.merk,
            quantity: item?.quantity,
            price: item?.price,
            total_price: item?.total_harga,
          })),
          meta: meta,
        };
      },
    }),

    getPharmacyTransactionByPatientExport: builder.query<
      string,
      {
        startDate: string;
        endDate: string;
        patientId: string;
      }
    >({
      query: (args) => {
        return {
          url: `${subEndpoint}/pharmacy/transaction/pasien/export`,
          method: "GET",
          params: {
            ...args,
          },
          responseHandler: async (response) => {
            if (response.ok) {
              const blob = await response.blob();
              const objectUrl = URL.createObjectURL(blob);
              return objectUrl;
            } else {
              throw new Error("Network response was not ok");
            }
          },
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPatientKunjunganReportQuery,
  useGetPatientKunjunganExportQuery,
  useGetPatientTransaksiReportQuery,
  useGetPatientTransaksiExportQuery,
  useGetDrugsReportQuery,
  useGetDrugsExportQuery,
  useGetPatientReportQuery,
  useGetPatientExportQuery,
  useGetDoctorReportQuery,
  useGetDoctorExportQuery,
  useGetPolyclinicReportQuery,
  useGetPolyclinicExportQuery,
  useGetProcedureReportQuery,
  useGetProcedureExportQuery,
  useGetPharmacyTransactionReportQuery,
  useGetPharmacyTransactionExportQuery,
  useGetTransactionByPolyReportQuery,
  useGetTransactionByPolyExportQuery,
  useGetTransactionByDoctorReportQuery,
  useGetTransactionByDoctorExportQuery,
  useGetProcedureTransactionByPatientReportQuery,
  useGetProcedureTransactionByPatientExportQuery,
  useGetPharmacyTransactionByPatientReportQuery,
  useGetPharmacyTransactionByPatientExportQuery,
  util: { resetApiState: resetReportApi },
  endpoints: {
    getPatientKunjunganReport,
    getPatientKunjunganExport,
    getPatientTransaksiReport,
    getPatientTransaksiExport,
    getDrugsReport,
    getDrugsExport,
    getPatientReport,
    getPatientExport,
    getDoctorReport,
    getDoctorExport,
    getPolyclinicReport,
    getPolyclinicExport,
    getProcedureReport,
    getProcedureExport,
    getPharmacyTransactionReport,
    getPharmacyTransactionExport,
    getTransactionByPolyReport,
    getTransactionByPolyExport,
    getTransactionByDoctorReport,
    getTransactionByDoctorExport,
    getProcedureTransactionByPatientReport,
    getProcedureTransactionByPatientExport,
    getPharmacyTransactionByPatientReport,
    getPharmacyTransactionByPatientExport,
  },
} = reportApi;
