import React, { useMemo } from "react";
import { Column } from "react-table";
import { format } from "date-fns";

import {
  ReportTransactionByPoliItem,
  ReportTransactionByPoliList,
} from "types/api/report";
import useFormatRupiah from "hooks/utils/useFormatRupiah";

import { StyledTable } from "Pages/Report/components/ReportTableVariant/style";
import CustomPaginationTable from "components/BasicTable/CustomPaginationTable/CustomPaginationTable";

type Props = {
  data?: ReportTransactionByPoliList;
  page: number;
  take: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setTake: React.Dispatch<React.SetStateAction<number>>;
};

const TableTemplate = ({ data, page, take, setPage, setTake }: Props) => {
  const { formatRupiah } = useFormatRupiah();
  const tableData = data?.entities ?? [];

  const columns: Column<ReportTransactionByPoliItem>[] = useMemo(() => {
    return [
      {
        Header: "No.",
        id: "row_number",
        accessor: (_, i) => (page - 1) * take + i + 1,
        maxWidth: 40,
      },
      {
        Header: "Waktu",
        accessor: "time",
        Cell: ({ value }) => (
          <>{value ? format(new Date(value), "dd MMM yyyy, HH:mm") : "-"}</>
        ),
      },
      {
        Header: "Nama Pasien",
        accessor: (row) => row.patient_name || "-",
      },
      {
        Header: "No. RM",
        accessor: (row) => row.encounter || "-",
      },
      {
        Header: "Poli",
        accessor: (row) => row.polyclinic_name || "-",
      },
      {
        Header: "Tindakan",
        accessor: (row) => row.procedure_name || "-",
      },
      {
        Header: "Harga",
        accessor: "rate",
        Cell: ({ value }) => <>{value ? formatRupiah(value) : "-"}</>,
      },
    ];
  }, [page, take, formatRupiah]);

  return (
    <>
      <StyledTable
        columns={columns}
        data={tableData}
        isHideNumbering
        showPagination={false}
        noDataText="No Data"
      />

      <CustomPaginationTable
        hasilText={`Memperlihatkan ${tableData.length} dari ${
          data?.meta?.itemCount ?? 0
        } Data`}
        setLimit={setTake}
        page={page}
        setPage={setPage}
        take={take}
        meta={{
          itemCount: data?.meta.itemCount ?? 0,
          page,
          offset: 0,
          pageCount: data?.meta.pageCount ?? 0,
        }}
      />
    </>
  );
};

export default TableTemplate;
