import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { UsedAPI } from "api";
import CookieMonster from "config/CookieMonster";

export const baseApi = createApi({
  reducerPath: "baseApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}`,
    prepareHeaders: (headers) => {
      const token = CookieMonster.loadCookie("access_token");
      const temporary_token = localStorage.getItem("temporary_access_token");
      if (temporary_token) {
        headers.set("Authorization", `Bearer ${temporary_token}`);
      } else {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
  tagTypes: [
    "Analytics",
    "Template",
    "Report",
    "Users",
    "Antenatal",
    "Bpjs-Kelompok",
    "Bpjs-Kelompok-Peserta",
    "Bpjs-Prolanis",
  ],
});
